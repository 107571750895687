import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const Ayataya = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/ayataya/ayataya-1.jpg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/ayataya/ayataya-2.jpg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/ayataya/ayataya-3.jpg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "",
        "",
        "",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Ayataya" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-24 mr-5">
                            <StaticImage src="../../images/brand/brand-3.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">Ayataya</h2>
                        <p className="font-light text-gray-600">8am - 11.30pm daily</p>
                        <p className="font-light text-gray-600">For enquiries: 014-6247022</p>
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">               
                            Situated in the prestigious Central Gurney, Ayataya by Piccadilly is a serene retreat within Penang’s vibrant scene, providing a harmonious blend of relaxation, exquisite cuisine and breathtaking seafront views. Their rich array of dishes showcases a fusion of diverse culinary traditions, from delectable pastries, artisanal coffee to sophisticated gastronomic offerings. Embracing Penang's allure, Ayataya infuses local flavours into Creative-Fusion Italian-Western dishes, appealing to both adventurous travelers and locals. Step into their elegantly rustic space, offering a cozy ambiance in line with Central Gurney's luxury commitment. Ayataya transits seamlessly from a casual hangout to an elegant dining venue, providing a serene haven for relaxation.                        </p>
                        <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-11-ayataya.jpg`} {...imageProps}/>
                        </div>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default Ayataya
